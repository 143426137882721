<template>
    <div>
        <!-- Home Banner -->
        <div class="mx-2"></div>
        <section class="home-banner">
            <div class="container">
                <div class="title">
                    <h4>
                        {{ $t('Home.hero.title') }}
                        <span> {{ $t('Home.hero.titleSpan') }}</span>
                    </h4>
                    <p>{{ $t('Home.hero.subtitle') }}</p>
                    <a
                        href="https://apps.fortnox.se/fs/fs/login.php?relay=%23%2Fmarketplace%2Fapp-details%2F51We0QQmNTAE"
                        class="button"
                    >
                        {{ $t('Home.hero.startedBtn') }}
                        <img src="@/assets/mainImages/icons/arrow-right.svg" />
                    </a>
                </div>
            </div>
        </section>
        <!-- Home Banner End -->

        <section class="home-section-03 mx-2">
            <div class="container">
                <p>
                    {{ $t('Home.section.firstParagraph') }}
                    <span>{{ $t('Home.section.span') }}</span>
                    {{ $t('Home.section.secondParagraph') }}
                </p>
            </div>
        </section>

        <section class="home-img-banner">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-8">
                        <img src="@/assets/mainImages/dashboard.png" alt="" />
                    </div>
                </div>
            </div>
        </section>

        <!-- Home Pricing -->
        <section class="home-pricing">
            <div class="container">
                <div class="title">
                    <h2>{{ $t('Home.pricing.title') }}</h2>
                    <br /><br />
                </div>
                <div class="title">
                    <p>
                        {{ $t('Home.pricing.subtitle') }}
                    </p>
                </div>

                <div class="row mx-2">
                    <div
                        v-for="(data, index) in pricing"
                        :key="index"
                        class="col-lg-4"
                    >
                        <div
                            :class="data.cardClass"
                            class="d-flex flex-column"
                            style="justify-content: space-around"
                        >
                            <div class="top-action" style="gap: 1rem">
                                <h6 class="text-capitalize">
                                    {{ data.title }}
                                </h6>
                                <h4>
                                    <span>Fr. {{ data.price }} kr</span>/
                                    {{ $t('Home.pricing.month') }}
                                </h4>

                                <a
                                    href="https://apps.fortnox.se/fs/fs/login.php?relay=%23%2Fmarketplace%2Fapp-details%2F51We0QQmNTAE"
                                    :class="data.btnClass"
                                >
                                    {{ $t('Home.hero.startedBtn') }}
                                </a>
                            </div>

                            <ul>
                                <h4>{{ $t('Home.pricing.features') }} :</h4>
                                <li
                                    v-for="(item, index) in data.list"
                                    :key="index"
                                >
                                    <div class="icon">
                                        <i class="fa fa-check"></i>
                                    </div>
                                    {{ item }}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- Home Pricing End -->

        <!-- Home FAQ  -->
        <section class="home-faq" style="min-height: 390px">
            <div class="container">
                <div class="title">
                    <h2>{{ $t('Home.faq.title') }}</h2>
                </div>

                <div class="row justify-content-center">
                    <div
                        v-for="(data, index) in faq"
                        :key="index"
                        class="col-lg-5 d-flex flex-column"
                    >
                        <b-card
                            v-for="(item, index) in data.data"
                            :key="index"
                            no-body
                            bg-variant="transparent"
                            class="shadow-0 mb-0"
                            style="box-shadow: none"
                        >
                            <b-card-header role="tab" header-tag="header">
                                <b-button
                                    block
                                    :aria-controls="item.id"
                                    class="p-0 bg-transparent"
                                    variant="transparent"
                                    @click="item.visible = !item.visible"
                                >
                                    <div class="d-flex" style="gap: 0.5rem">
                                        <div
                                            class="icon d-flex accodionIcon justify-content-center bg-green-darken-2"
                                        >
                                            <i
                                                v-if="item.visible"
                                                class="fa fa-angle-up"
                                            ></i>
                                            <i
                                                v-else
                                                class="fa fa-angle-down"
                                            ></i>
                                        </div>
                                        <p
                                            class="mb-0 pb-0 text-left font-weight-bold text-green-darken-2"
                                            style="font-size: 20px"
                                        >
                                            {{ item.title }}
                                        </p>
                                    </div>
                                </b-button>
                            </b-card-header>
                            <b-collapse
                                :id="item.id"
                                visible
                                v-model="item.visible"
                                accordion="my-accordion"
                                role="tabpanel"
                                class="px-2"
                            >
                                <b-card-body
                                    class="pt-0 pl-0 ml-2"
                                    style="padding-left: 27px"
                                >
                                    <b-card-text>
                                        {{ item.body }}
                                    </b-card-text>
                                </b-card-body>
                            </b-collapse>
                        </b-card>
                    </div>
                </div>
            </div>
        </section>
        <!-- Home FAQ End -->

        <!-- Home Banner 02 -->
        <section class="home-banner-02">
            <div class="container">
                <div class="text">
                    <p>
                        {{ $t('Home.homeBanner02.paragraph1') }}
                        <span> {{ $t('Home.homeBanner02.span1') }}</span>
                        {{ $t('Home.homeBanner02.paragraph2') }}
                        <span>
                            {{ $t('Home.homeBanner02.span2') }}
                        </span>
                        {{ $t('Home.homeBanner02.paragraph3') }}
                    </p>
                    <a
                        href="https://apps.fortnox.se/fs/fs/login.php?relay=%23%2Fmarketplace%2Fapp-details%2F51We0QQmNTAE"
                        class="button"
                    >
                        {{ $t('Home.homeBanner02.action') }}
                        <img src="@/assets/mainImages/icons/arrow-right.svg" />
                    </a>
                </div>
            </div>
        </section>
        <!-- Home Banner 02 End -->
    </div>
</template>

<script>
import Ripple from 'vue-ripple-directive';
import axiosIns from '@/libs/axios';

export default {
    directives: {
        Ripple,
    },
    data() {
        return {
            visible: false,

            pricing: [],
            faq: this.$t('Home.faq.faqList'),
        };
    },
    computed: {
        getLang() {
            return localStorage.getItem('mainLang');
        },
    },
    methods: {
        getPricing() {
            axiosIns
                .get('plan-info/', {
                    transformRequest: (data, headers) => {
                        delete headers.Authorization;
                        return data;
                    },
                })
                .then(res => {
                    const {data} = res.data;

                    data.sort((a, b) => {
                        return b.level - a.level;
                    });
                    data.forEach((element, index) => {
                        this.pricing.push({
                            cardClass: index == 1 ? 'item active' : 'item',
                            title: element.plan_name,
                            price: element.price,
                            btnClass:
                                index == 0 && index == 1
                                    ? 'button'
                                    : 'button active',
                            list:
                                this.getLang == 'sv'
                                    ? element['swe_features']
                                    : element['swe_features'],
                        });
                    });
                });
        },
    },

    mounted() {
        this.getPricing();
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/firstlayout/home.scss';

.accodionIcon {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    color: white;
    font-size: 18px;
}
</style>
